import data from '../../fields';

export default {
  requiredFields: () => {
    return [
      'violation_type_id',
      'obj_type_id',
      'area_id',
      'address_district_id',
      'address_full',
      'audit_doc_number',
      'audit_doc_date',
      'file_audit_doc_files',
      'file_audit_doc_photos',
      'warranty_measures_taken'
    ];
  },
  sections: (object, areaId) => {
    function getFiles(folder) {
      return object.files.filter(el => el.folder_code == folder);
    }
    return [
      {
        editable: true,
        title: 'ВЫЯВЛЕНИЕ ОБЪЕКТА',
        params: [
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'УЧЕТНЫЙ НОМЕР ОБЪЕКТА И ДАТА ПОСТАНОВКИ НА УЧЕТ'
            }
          },
          {
            label: '',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: true,
              placeholder: ''
            },
            property: 'obj_registration_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: '',
              disabled: true,
              name: 'obj_registration_date'
            },
            property: 'obj_registration_date'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'ОБЩИЕ СВЕДЕНИЯ'
            }
          },
          {
            label: 'ТИП НАРУШЕНИЯ',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.violationTypes(32),
              placeholder: 'Выберите тип нарушения'
            },
            property: 'violation_type_id'
          },
          {
            label: 'СОБСТВЕННИК ИЗВЕСТЕН',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              name: 'owner_is_known',
              items: data.boolRadio()
            },
            required: ['owner_info'],
            fieldClass: 'mb-10',
            property: 'owner_is_known'
          },
          {
            label: 'ИНФОРМАЦИЯ О СОБСТВЕННИКЕ',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите описание',
              rows: 3
            },
            property: 'owner_info'
          },
          {
            label: 'ТИП ОБЪЕКТА И ВИД ОБЪЕКТА',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.objectTypes(4),
              placeholder: 'Выберите тип объекта'
            },
            property: 'obj_type_id'
          },
          {
            label: 'ГО / МР',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.areas().filter(area => area.area_id !== -1),
              disabled: object.obj_registration_number,
              placeholder: 'Выберите ГО / МР'
            },
            property: 'area_id'
          },
          {
            label: 'РАЙОН ОБЪЕКТА',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              items: data.districts(areaId),
              disabled:
                !data.districts(areaId).length == 1 ||
                areaId == -1 ||
                object.obj_registration_number,
              placeholder: 'Выберите район'
            },
            property: 'address_district_id'
          },
          {
            label: 'АДРЕС ОБЪЕКТА',
            component: 'auto-address',
            fieldClass: 'mb-10',
            componentAttrs: {
              object: object,
              placeholder: '',
              areaId: areaId,
              disabled: object.obj_registration_number
            },
            property: 'address_full'
          },
          {
            label: 'ОПИСАНИЕ АДРЕСА',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите описание',
              rows: 3
            },
            property: 'address_info'
          },
          {
            component: 'mapPlace',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: object.obj_registration_number != null,
              center: object.geo_polygon
            },
            property: 'geo_polygon'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title:
                'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ НЕЗАКОННОГО ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: object.guid,
              placeholder: 'Введите номер'
            },
            property: 'audit_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: object.guid,
              name: 'property'
            },
            property: 'audit_doc_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_audit_doc_files',
            componentAttrs: {
              disabled_edit: object.guid,
              border_top: true,
              tooltipText: 'Акт проверки',
              proxyData: getFiles('audit_doc_files'),
              allowMultiple: 'true',
              uploadFolder: 'audit_doc_files'
            },
            type: 'form_generator'
          },
          {
            component: 'editPhotos',
            property: 'file_audit_doc_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled_edit: object.guid,
              border_top: false,
              title: '',
              proxyData: getFiles('audit_doc_photos'),
              allowMultiple: 'true',
              uploadFolder: 'audit_doc_photos'
            }
          }
        ]
      },
      {
        editable: true,
        title: 'ОБЕСПЕЧИТЕЛЬНЫЕ МЕРЫ',
        params: [
          {
            label: 'НАЛОЖЕНЫ ЛИ ОБЕСПЕЧИТЕЛЬНЫЕ МЕРЫ?',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              name: 'warranty_measures_taken',
              items: data.boolRadio()
            },
            fieldClass: 'mb-10',
            required: ['warranty_measures_info'],
            property: 'warranty_measures_taken'
          },
          {
            label: 'КОММЕНТАРИЙ',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите комментарий',
              rows: 3
            },
            property: 'warranty_measures_info'
          }
        ]
      },
      {
        editable: true,
        title: 'ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ',
        params: [
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'ПЕРИОД, ОТВЕДЕННЫЙ НА ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ ОБЪЕКТА'
            }
          },
          {
            label: 'НАЧАЛО И ОКОНЧАНИЕ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'property'
            },
            required: ['remove_voluntary_date_to'],
            property: 'remove_voluntary_date_from'
          },
          {
            label: '-',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'property'
            },
            property: 'remove_voluntary_date_to'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title:
                'СВЕДЕНИЯ О ПРОДЛЕНИИ ПЕРИОДА, ОТВЕДЕННОГО НА ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ ОБЪЕКТА'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'ml-10',
            componentAttrs: {
              title:
                'СВЕДЕНИЯ ОБ АКТЕ ТЕРРИТОРИАЛЬНОГО ОРГАНА АДМИНИСТРАЦИИ ГОРОДА О ПРОДЛЕНИИ'
            }
          },
          {
            label: 'НАИМЕНОВАНИЕ',
            component: 'uiInput',
            fieldClass: 'mb-10 ml-10',
            componentAttrs: {
              placeholder: 'Введите наименование'
            },
            property: 'remove_ext_doc_type_name'
          },
          {
            label: 'НОМЕР И ДАТА СОСТАВЛЕНИЯ',
            component: 'uiInput',
            fieldClass: 'mb-10 ml-10',
            componentAttrs: {
              placeholder: 'Введите наименование'
            },
            property: 'remove_ext_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10 ml-10',
            componentAttrs: {
              name: 'remove_ext_doc_date'
            },
            property: 'remove_ext_doc_date'
          },
          {
            label: 'СРОК ПРОДЛЕНИЯ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_ext_date_to'
            },
            property: 'remove_ext_date_to'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'АКТ ПРОВЕРКИ ИСПОЛНЕНИЯ ТРЕБОВАНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ'
            }
          },
          {
            label: 'НОМЕР И ДАТА СОСТАВЛЕНИЯ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            required: [
              'remove_voluntary_doc_date',
              'remove_voluntary_is_done',
              'file_remove_voluntary_files',
              'file_remove_voluntary_photos'
            ],
            property: 'remove_voluntary_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_voluntary_doc_date'
            },
            property: 'remove_voluntary_doc_date'
          },
          {
            label:
              'РЕЗУЛЬТАТЫ ПРОВЕРКИ ИСПОЛНЕНИЯ ТРЕБОВАНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ (ДЕМОНТИРОВАН/ НЕ ДЕМОНТИРОВАН)',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              name: 'remove_voluntary_is_done',
              items: data.boolRadio()
            },
            fieldClass: 'mb-10',
            property: 'remove_voluntary_is_done'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_remove_voluntary_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'акт проверки исполнения требования',
              proxyData: getFiles('remove_voluntary_files'),
              allowMultiple: 'true',
              uploadFolder: 'remove_voluntary_files'
            }
          },
          {
            component: 'editPhotos',
            property: 'file_remove_voluntary_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: true,
              title: '',
              proxyData: getFiles('remove_voluntary_photos'),
              allowMultiple: 'true',
              uploadFolder: 'remove_voluntary_photos'
            }
          }
        ]
      },
      {
        editable: true,
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'РЕКВИЗИТЫ РАСПОРЯЖЕНИЯ ГЛАВЫ ТЕРРИТОРИАЛЬНОГО ОРГАНА О ПРИНУДИТЕЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР СЭД И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            required: [
              'remove_force_doc_date',
              'remove_force_bulletin_number',
              'remove_force_bulletin_date',
              'file_remove_force_files',
              'remove_plan_date'
            ],
            property: 'remove_force_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_force_doc_date'
            },
            property: 'remove_force_doc_date'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ ОБ ОФИЦИАЛЬНОМ ОПУБЛИКОВАНИИ'
            }
          },
          {
            label: '№ ОФИЦИАЛЬНОГО БЮЛЛЕТЕНЯ ОРГАНОВ МЕСТНОГО САМОУПРАВЛЕНИЯ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            property: 'remove_force_bulletin_number'
          },
          {
            label: 'ДАТА',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_force_bulletin_date'
            },
            property: 'remove_force_bulletin_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_remove_force_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'распоряжение  о принудительном демонтаже',
              proxyData: getFiles('remove_force_files'),
              allowMultiple: 'true',
              uploadFolder: 'remove_force_files'
            }
          },
          {
            label: 'ДАТА ПЛАНИРУЕМОГО ДЕМОНТАЖА ОБЪЕКТА',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_plan_date'
            },
            property: 'remove_plan_date'
          },
          {
            label: 'ДАТА ФАКТИЧЕСКОГО ДЕМОНТАЖА ОБЪЕКТА',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_actual_date'
            },
            property: 'remove_actual_date'
          }
        ]
      },
      {
        editable: true,
        title: 'ПОВТОРНЫЕ СВЕДЕНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'Повторные сведения о добровольном демонтаже объекта'
            }
          },
          {
            label: 'Добровольный демонтаж',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              name: 'sec_remove_voluntary_is_done',
              items: data.boolRadio()
            },
            required: [
              'sec_remove_voluntary_doc_number',
              'sec_remove_voluntary_doc_date',
              'file_sec_remove_voluntary_files',
              'file_sec_remove_voluntary_photos'
            ],
            fieldClass: 'mb-10',
            property: 'sec_remove_voluntary_is_done'
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'Акт проверки исполнения требования о добровольном демонтаже'
            }
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            label: 'Номер и дата Составления',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            property: 'sec_remove_voluntary_doc_number'
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'sec_remove_voluntary_doc_date'
            },
            property: 'sec_remove_voluntary_doc_date'
          },

          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_sec_remove_voluntary_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'акт проверки исполнения требования',
              proxyData: getFiles('sec_remove_voluntary_files'),
              allowMultiple: 'true',
              uploadFolder: 'sec_remove_voluntary_files'
            }
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            component: 'editPhotos',
            property: 'file_sec_remove_voluntary_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: true,
              title: '',
              proxyData: getFiles('sec_remove_voluntary_photos'),
              allowMultiple: 'true',
              uploadFolder: 'sec_remove_voluntary_photos'
            }
          }
        ]
      }
    ];
  }
};
